@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --links-color: #26DB8D;
    background: #0C0C1C;
}

a {
    color: var(--links-color);
}

@font-face {
    font-family: 'Proxima-Nova';
    src: local('Proxima-Nova'), url(../src/assets/fonts/Proxima_Nova/proxima-nova-normal.otf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Proxima-Nova';
    src: local('Proxima-Nova-Semibold'), url(../src/assets/fonts/Proxima_Nova/proxima-nova-semibold.otf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Proxima-Nova';
    src: local('Proxima-Nova-Bold'), url(../src/assets/fonts/Proxima_Nova/proxima-nova-bold.otf) format('truetype');
    font-weight: 700;
}

body, p, button, input, a {
    font-family: "Proxima-Nova", serif !important;
    font-variant: no-common-ligatures;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button > .__react_component_tooltip {
    font-size: 20px;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 14px;
  }
  
  *::-webkit-scrollbar-track {
    background: none;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #7A7E8C;
    border-radius: 30px;
    border-right: 6px solid #041742;
    border-top: 4px solid #041742;
    border-bottom: 4px solid #041742;
  }

.routerNavLink:hover:not(.active) {
    color: white !important;
}

.routerNavLink.active {
    color: theme('colors.ZBF_green');
}

.routerNavLink {
    color: #C8CFD5;
}

.copy-icon:hover {
    content: url(./assets/copy_icon_hover.png)
}

.pagination-button:hover {
    background-color: #1D1E26;
    color: white;
}

.menu-option:hover {
    background: #1D1E26;
}

#tokenmint_link_menu,
#filters_menu {
    display: none;
}

.tokenmint_link:hover~#tokenmint_link_menu,
#tokenmint_link_menu:hover,
.filters_menu_button:hover > #filters_menu,
#filters_menu:hover {
    display: block;
}

.filters_menu_button:hover > #filter_arrow,
#filters_menu:hover~#filter_arrow {
    transform: rotate(180deg) !important;
}