.calendar-button:hover {
    content: url(../assets/calendar-white.png)
}
.react-calendar__viewContainer {
    font-family: 'Proxima-Nova';
}
.react-calendar { 
    width: 400px;
    max-width: 100%;
    background-color: #0C0C1C;
    color: #222;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
   }
   .react-calendar__navigation button {
    color: theme('colors.ZBF_green');
    min-width: 44px;
    background: none;
    font-size: 1.25em;
    margin-top: 8px;
   }
   
   .react-calendar__navigation__arrow.react-calendar__navigation__next2-button > div > img,
   .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button > div > img,
   .react-calendar__navigation .react-calendar__navigation__arrow > img,
   .react-calendar__navigation .react-calendar__navigation__arrow > img {
    content: url(../assets/green_arrow_left.png)
   }
   
   .react-calendar__navigation__arrow.react-calendar__navigation__next2-button:hover:enabled > div > img,
   .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button:hover:enabled > div > img,
    .react-calendar__navigation .react-calendar__navigation__arrow:enabled:hover > img,
   .react-calendar__navigation .react-calendar__navigation__arrow:enabled:focus > img {
    background-color: theme('colors.ZBF_green');
    content: url(../assets/white_arrow_left.png)
   }

   .react-calendar__navigation__arrow.react-calendar__navigation__next2-button:disabled > div > img,
   .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button:disabled > div > img,
    .react-calendar__navigation > .react-calendar__navigation__arrow:disabled > img,
    .react-calendar__navigation > .react-calendar__navigation__arrow:disabled > img {
    content: url(../assets/gray_arrow_right.png);
    transform: rotate(0deg)
   }

   .react-calendar__navigation > button.react-calendar__navigation__arrow:disabled.react-calendar__navigation__next-button:disabled,
   .react-calendar__navigation > button.react-calendar__navigation__arrow:disabled.react-calendar__navigation__next-button:disabled,
   .react-calendar__navigation > button.react-calendar__navigation__arrow:disabled.react-calendar__navigation__next2-button:disabled,
   .react-calendar__navigation > button.react-calendar__navigation__arrow:disabled.react-calendar__navigation__next2-button:disabled {
       background-color: transparent;
   }

   .react-calendar__tile.react-calendar__month-view__days__day:disabled,
   .react-calendar__tile:disabled  {
    background-color: transparent;
    color: #7A7E8C;
    }
    
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color: theme('colors.ZBF_green');
    color: white;
   }
   .react-calendar__navigation button[disabled] {
    background-color: theme('colors.ZBF_green');
    color: white;
   }
   abbr[title] {
    text-decoration: none;
   }
   .react-calendar__month-view__weekdays {
       text-transform: capitalize;
   }
   .react-calendar__month-view__days__day.react-calendar__tile {
       font-size: 14px;
   }

   /* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */

   .react-calendar__month-view__weekdays__weekday,
   .react-calendar__month-view__days__day,
   .react-calendar__year-view__months__month,
   .react-calendar__decade-view__years__year,
   .react-calendar__century-view__decades__decade {
    color: white;
   }
   .react-calendar__month-view__days__day--neighboringMonth {
       color: #7A7E8C;
   }
   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
    background: linear-gradient(47.91deg, #0E9DE5 0%, #26DB8D 105.63%), #0E9DE5;
    font-weight: bold;
   }
   .react-calendar__tile--hasActive:enabled:hover {
    background: linear-gradient(47.91deg, #0E9DE5 0%, #26DB8D 105.63%), #0E9DE5;
   }
   .react-calendar__tile--hasActive {
    background: theme('colors.ZBF_green');
    font-weight: bold;
    color: white;
   }
   .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
   }
   .react-calendar__tile--range {
    background: theme('colors.ZBF_green');
    color: white;
    font-weight: bold;
   }
   .react-calendar__tile--now:not(.react-calendar__tile--range) {
    background: none;
    color: white;
    border: solid theme('colors.ZBF_green') 1px;
   }